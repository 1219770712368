input[type="file"] {
  display: none;
}

.img-upload {
  width: 100px;
  height: 100px;
  border-style: double;
  border-width: 3px;
  border-color: #ccc;
  background-color: #f3f1f1;
  text-align: center;
  border-radius: 5px;

  &>.max {
    width: 100%;
    display: flex;
  }
}

.error-message {
  padding: 8px 14px;
  color: #f44336;
  font-size: 13px;
}